<div class="modal-body text-center">
    <h3><b>Please Update Your Credit Card</b></h3>
    <p>
        We've enhanced our payment system for better security, and you’ll need to update your card to continue making purchases
    </p>
    <p *ngIf="canUseBonus">
        As a thank you, enjoy <b>{{ currency.asciiSymbol }}10 free credit</b> once updated!
    </p>
    <p><button (click)="goToAccount()" class="btn btn-def">Update</button></p>
    <p *ngIf="!forceCyberSource"><button (click)="doItLater()" class="btn btn-link">Do It Later</button></p>
</div>
