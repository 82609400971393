import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {UtilService} from '../../../../../services/util.service';
import {Currency} from '../../../../../entity/currency';

@Component({
  selector: 'app-cybersource-modal',
  templateUrl: './cybersource-modal.component.html',
  styleUrls: ['./cybersource-modal.component.scss']
})
export class CybersourceModalComponent implements OnInit {
  forceCyberSource: boolean;
  canUseBonus: boolean;
  currency: Currency = {
    asciiSymbol: '$',
    id: 1,
    sign: 'USD'
  };
  constructor(
      public bsModalRef: BsModalRef,
      private router: Router,
      private utilService: UtilService,
  ) {
  }


  ngOnInit() {
    const aux = JSON.parse(this.utilService.localStorageGetItem('currency'));
    if (aux !== null) {
      this.currency = aux;
    }
  }


  goToAccount() {
    this.bsModalRef.hide();
    this.router.navigate(['customer/credit-card']);
  }

  doItLater() {
    localStorage.setItem('checkCyberSource', new Date().toString());
    this.bsModalRef.hide();
  }

}
