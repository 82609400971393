import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../../modules/shared/services/back-router';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Article} from '../../../entity/article';
import {SignFormToAddCcResponseDto} from './dto/sign-form-to-add-cc.response.dto';

@Injectable({
    providedIn: 'root',
})
export class UserCustomerService {
    constructor(private httpClient: HttpClient, private br: BackRouter) {
    }

    registrationStep3() {
        try {
            return this.httpClient.get<SignFormToAddCcResponseDto>(
                this.br.getNestJsUrl() + '/user-customer/registration/step-3',
                {headers: {ignoreLoadingBar: ''}}
            ).toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
    updateCC() {
        try {
            return this.httpClient.get<SignFormToAddCcResponseDto>(
                this.br.getNestJsUrl() + '/user-customer/update/cc',
                {headers: {ignoreLoadingBar: ''}}
            ).toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
