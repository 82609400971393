/* tslint:disable:max-line-length */
import {
    AfterViewChecked,
    AfterViewInit,
    ApplicationRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {DOCUMENT, isPlatformBrowser, Location} from '@angular/common';
import {ApplicationStateService} from './services/application.state.service';
import {Meta, Title} from '@angular/platform-browser';
import {AllEmiterService} from './services/all-emiter.service';
import {SEOService} from './services/seo.service';
import {SubSink} from 'subsink';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfigService} from './services/config.service';
import {environment} from '../environments/environment';
import {WINDOW} from './providers/window.provider';
import {UserService} from './services/user.service';
import {ElectronService} from './services/electron.service';
import {TwimlService} from './services/twiml';
import {GoogleAnalyticsEventsService} from './services/google-analytics-events.service';
import {DynamicScriptLoaderService} from './services/dynamic-script-loader.service';
import {UserDetails} from './modules/shared/entity/user-details';
import {finalize} from 'rxjs/operators';
import {CheckForUpdateService} from './services/check-for-update.service';
import {LogUpdateService} from './services/log-update.service';
import {PromptUpdateService} from './services/prompt-update.service';
import {LoadableComponent, LoadableService} from 'ngx-loadable';
import {CookieService} from 'ng2-cookies';
// import * as Sentry from '@sentry/browser';
import {ConsultantService} from './modules/consultant/services/consultant.service';
import {SocketIoService} from './services/socket-io.service';
import {UtilService} from './services/util.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {HttpHeaders} from '@angular/common/http';
import {loadScript} from '@paypal/paypal-js';
import {PaymentService} from './modules/customer/services/payment.service';
import {RegistrationStep2Component} from './modules/shared/components/registration-step2/registration-step2.component';
import {RegistrationStep3Component} from './modules/shared/components/registration-step3/registration-step3.component';
import {CcAttemptsService} from './services/nestjs/logs/cc-attempts/cc-attempts.service';
import {PromotiosResolverService} from './resolvers/promotios-resolver.service';
import {UserCreditCardService} from './services/nestjs/user-credit-card/user-credit-card.service';
import {AuthNestjsService} from './services/nestjs/auth/auth-nestjs.service';
import {Observable} from 'rxjs';
import {Exception} from 'twilio-client/es5/twilio/util';
import {ReaderService} from './modules/shared/services/reader-service';

declare var FB: any;
declare const Twilio: any;
import * as Sentry from '@sentry/angular';
import {
    CybersourceModalComponent
} from "./modules/shared/components/modals/cybersource-modal/cybersource-modal.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {

    public waiting = false;
    public loading = false;
    public version: string = environment.appVersion;
    @ViewChild('page', {static: false}) page: ElementRef;
    @ViewChild('reconnectDialog', {static: false}) reconnectDialog: ElementRef;
    @ViewChild('myLauncher', {static: false}) myLauncher: ElementRef;
    @ViewChild('footerComponent') footerComponent: LoadableComponent;
    @ViewChild('headerComponent') headerComponent: LoadableComponent;
    // userInactive: Subject<any> = new Subject();
    showHeaderComponent = false;
    headerComponentShown = false;
    private intervalPing: Subscription = null;
    private intervalReconnecting: Subscription = null;
    private subs = new SubSink();
    public electron;
    public uid;
    private twimlToken = '';

    public prod = false;


    loadFirstTime = true;
    private isBrowser: boolean = isPlatformBrowser(this.platformId);
    private chatRunning = false;

    private previousUrl = '/';
    private paypal;
    public lastActiveDate$: Observable<Date>;
    public timeOutLastActive;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private titleService: Title,
        private allEmiterService: AllEmiterService,
        private seoService: SEOService,
        private bsModalService: BsModalService,
        private configService: ConfigService,
        @Inject(WINDOW) private window: Window,
        public userService: UserService,
        private electronService: ElectronService,
        private gAE: GoogleAnalyticsEventsService,
        private dynamicScriptLoader: DynamicScriptLoaderService,
        private appRef: ApplicationRef,
        private checkForUpdateService: CheckForUpdateService, // Required to check for updates even if it is not use,

        private loadableService: LoadableService,
        private cookieService: CookieService,
        private consultantService: ConsultantService,
        private socketIoService: SocketIoService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private utilService: UtilService,
        private activatedRoute: ActivatedRoute,
        private logger: NGXLogger,
        private paymentService: PaymentService,
        private route: ActivatedRoute,
        private location: Location,
        private logService: CcAttemptsService,
        private userCreditCardService: UserCreditCardService,
        private readerService: ReaderService,
    ) {
        this.pwaInstallation();
        const self = this;
        this.setCredentialsForLogger();
        this.prod = (environment.name === 'production' || environment.name === 'stage') ? true : false;
        this.ping().then();


        // this.utilService.localStorageSetItem('prevUrl', this.previousUrl);
        this.subs.sink = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.loadFirstTime) {
                    this.loadFirstTime = false;
                    const userAgent = this.window.navigator.userAgent;
                    this.loadScripts();
                    this.loadStyles();
                    self.dynamicScriptLoader.load('zdesk').then(data => {
                        // Script Loaded Successfully
                        // @ts-ignore
                        // self.document.querySelector('#myLauncher').style.display = 'block';
                        self.myLauncher.nativeElement.classList.add('show-launcher');

                        const role = self.utilService.localStorageGetItem('role');
                        if (role === 'ROLE_READER') {
                            // @ts-ignore
                            self.document.querySelector('#myLauncher').style.display = 'none';
                        } else {
                            // @ts-ignore
                            self.document.querySelector('#myLauncher').style.display = 'block';
                        }
                        // @ts-ignore
                        zE('webWidget', 'hide');

                        // @ts-ignore
                        zE('webWidget:on', 'close', function () {
                            // @ts-ignore
                            zE('webWidget', 'hide');
                            // @ts-ignore
                            // self.document.querySelector('#myLauncher').style.opacity = 1;
                            self.myLauncher.nativeElement.classList.add('show-launcher');
                        });

                        // @ts-ignore
                        zE('webWidget:on', 'open', function () {
                            const webWidget = self.document.getElementById('webWidget');
                            webWidget.style.cssText = 'width: 374px; height: 572px; position: fixed; opacity: 1; border: 0px; transition-duration: 250ms; transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); transition-property: opacity, top, bottom; z-index: 999999; max-height: calc(100vh - 32px); right: 0px; bottom: 0px;';

                        });
                    }).catch(error => {
                    });


                }
                if (event.url !== '/chat' && !event.url.includes('credit_card')) {
                    this.previousUrl = event.url;
                    this.utilService.localStorageSetItem('prevUrl', this.previousUrl);
                } else {
                    if (event.url !== '/chat') {
                        this.utilService.localStorageSetItem('prevUrl', '/');
                    }

                }
                let routeData: ActivatedRoute = this.router.routerState.root;
                let routeTitle = '';
                while (routeData?.firstChild) {
                    routeData = routeData.firstChild;
                }
                if (routeData.snapshot.data['title']) {
                    routeTitle = routeData?.snapshot.data['title'];
                }
                if (routeTitle) {
                    this.titleService.setTitle(`${routeTitle}`);
                }
            }
        });
        this.router.events
            .subscribe((event: NavigationStart) => {
                if (event.navigationTrigger === 'popstate') {
                    const prevUrl = self.utilService.localStorageGetItem('prevUrl');
                    // console.og('Back detected', prevUrl);
                    if (prevUrl === '/chat') {
                        this.router.navigate(['/']);
                    }
                }
            });
        this.checkIfRegistration();
        this.subs.sink = this.allEmiterService.subsUserLogOut =
            this.allEmiterService.invokeUserLogOut.subscribe(() => {
                try {
                    self.signOut();
                } catch (e) {
                }
                self.deleteData();
            });
        this.window.addEventListener('storage', (event) => {
            if (event.storageArea !== localStorage) {
                return;
            }
            if (event.key === 'uid') {
                self.utilService.setTimeout(function () {
                    self.window.location.reload();
                }, 3000);
            }
        });
        if (this.isBrowser) {
            this.socketIoService.connectOrGet();
        }

        this.checkForUpdateService.checkForUpdates();

    }

    async loadPaypal() {
        try {
            this.paypal = await loadScript(this.paymentService.getPaypalConfiguration());
        } catch (error) {
            this.logger.error('failed to load the PayPal JS SDK script', error);
        }
    }

    async ngOnInit() {
        // console.og('App component init', {release: this.version, environment: environment.name});
        const self = this;
        this.router.events.subscribe(async event => {
            if (event instanceof NavigationEnd) {
                // In case the page is open using navigateUrl on the chat modal
                if (history?.state?.showHeader) {
                    this.showHeaderComponent = history.state.showHeader !== false;
                    this.chatRunning = history.state.chatRunning === false;
                } else {
                    // In case the page is open directly
                    this.showHeaderComponent = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
                    // this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
                    this.chatRunning = this.activatedRoute.firstChild.snapshot.data.chatRunning === true;
                }
                if (this.showHeaderComponent) {
                    try {
                        await this.loadableService.preload('header');
                        this.showHeaderComponent = true;
                        this.headerComponentShown = true;
                    } catch (e) {
                        console.error(e);
                    }
                }
                await this.classMainDiv();
            }
            if (event instanceof NavigationEnd) {
                await this.gAE.pageView(event.urlAfterRedirects);
                this.closeZDeskWidget();
                this.seoService.createLinkForCanonicalURL();
            }
        });
        // Set reconnecting to false, to avoid issues in case system is trying to reconnect and user refresh the page
        self.utilService.localStorageSetItem('reconnecting', 'false');
        if (this.userService.isLoggedIn()) {
            const token = this.utilService.localStorageGetItem('token');
            if (!token) {
                this.signOut();
            }
            self.setCredentialsForLogger();
            await this.userService.getDetailsToPromise();
            self.utilService.localStorageSetItem('firstTime', 'false');
            self.testConnection();
            try {
                const userDetails = this.userService.getData();
                Sentry.setUser({email: userDetails.nickName, id: userDetails.userId + ''});
            } catch (e) {
                Sentry.setUser(null);
            }

        }

        this.electron = environment.electron;


        if (this.userService.isLoggedIn()) {
            this.electronService.setDockMenu();
            // Load css
            self.dynamicScriptLoader.loadCss('bootstrapSwitch').then(data => {
            }).catch(error => {
            });
        }
        // await this.ping();
        // Obtain domain settings
        this.subs.sink = this.configService.getConfig().subscribe(config => {
            self.utilService.localStorageSetItem('currency', JSON.stringify(config.currency) + '');
            this.allEmiterService.onCurrencyChance(config.currency);
        });

        // if (!this.electron && !isScullyRunning()) {
        if (this.allEmiterService.subsWait === undefined) {
            this.subs.sink = this.allEmiterService.subsWait = this.allEmiterService.invokeWait.subscribe((data) => {
                this.loadDataFinished(data);
            });
        }
        if (this.allEmiterService.subsLoading === undefined) {
            this.subs.sink = this.allEmiterService.subsLoading = this.allEmiterService.invokeLoading.subscribe((data) => {
                this.loadingChange(data);
            });
        }

        await this.classMainDiv();
        this.subs.sink = this.allEmiterService.subsLoggedIn = this.allEmiterService.invokeLoggedIn.subscribe(async () => {
            this.uid = self.utilService.localStorageGetItem('uid');
            // Load css
            self.dynamicScriptLoader.loadCss('bootstrapSwitch').catch(error => {
            });
            const role = self.utilService.localStorageGetItem('role');
            if (role === 'ROLE_READER') {
                // @ts-ignore
                self.document.querySelector('#myLauncher').style.display = 'none';
                self.testConnection();
            }
            await this.classMainDiv();
            // this.setCredentialsForLogger();
        });

        await this.readerService.getCategoryList().toPromise();

    }


    // checkLastTimeActive() {
    //     this.timeOutLastActive = setTimeout(
    //         () => this.userInactive.next('User has been inactive for 15 seconds'),
    //         300000
    //     );
    //
    // }


    // @HostListener('window:keydown')
    // @HostListener('window:mousemove')
    // @HostListener('window:wheel')
    // checkUserActivity() {
    //     clearTimeout(this.timeOutLastActive);
    //     this.checkLastTimeActive();
    // }

    testConnection() {
        // const self = this;
        // if (this.interValTestConnection === null) {
        //     this.interValTestConnection = setInterval(() => {
        //         self.authNestjsService.test().then();
        //     }, 10000);
        // }

    }

    async ngAfterViewInit() {
        const self = this;
        this.uid = self.utilService.localStorageGetItem('uid');
        // Check if user has not test sound
        await this.checkSound();
        await this.checkIfCCExpired();
        await this.checkIfUseCyberSource();
        await self.showOffersAfterRegistration();


    }


    async initTwilioPhone() {
        const self = this;
        // User has logged in, so he can connect to twilio app
        // Generate token for the 'phone'
        // const auxToken: any = await this.twimlService.getToken(this.uid);
        // this.dynamicScriptLoader.load('twilio').then(data => {
        //     this.twimlToken = auxToken.token;
        //     Twilio.Device.setup(this.twimlToken);
        //
        //     Twilio.Device.ready(function() {
        //     });
        //
        //     Twilio.Device.incoming(async function(conn) {
        //         // const component: any = await import('./modules/shared/components/modals/income-call/income-call.component').then(cp => cp.IncomeCallComponent);
        //         // this.incomeModalRef = self.bsModalService.show(component, {
        //         //     backdrop: 'static',
        //         //     ignoreBackdropClick: true,
        //         //     initialState: {connection: conn},
        //         //     keyboard: false
        //         // });
        //         // this.incomeModalRef.content.onHide.subscribe(() => {
        //         //     if (this.incomeModalRef !== null) {
        //         //         this.incomeModalRef.hide();
        //         //         this.incomeModalRef = null;
        //         //     }
        //         // });
        //     });
        // }).catch(error => {
        // });

    }

    ngAfterViewChecked(): void {
    }

    loadDataFinished(data) {
        this.waiting = data;
        const displayPage = (!data) ? 'block' : 'none';
        if (displayPage === 'block') {
            this.page.nativeElement.style.display = displayPage;
        }

    }

    loadingChange(data: boolean) {
        this.loading = data;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /**
     * Validate that is a reader, is mobile and notifications are enabled, if is not reader
     * or is mobile, return true
     */
    checkNotifications() {
        const self = this;
        const isMobile = this.checkIfIsMobile();
        const role = self.utilService.localStorageGetItem('role');
        if (!isMobile && role === 'ROLE_READER') {
            if (!window.Notification) {
                return false;
            } else {
                // check if permission is already granted
                if (Notification.permission === 'granted') {
                    return true;
                } else {
                    // request permission from user
                    Notification.requestPermission().then(function (p) {
                        if (p === 'granted') {
                            return true;
                        } else {
                            return false;
                        }
                    }).catch(function (e) {
                        console.error(e);
                        return false;
                    });
                }
            }
        } else {
            return true;
        }
    }

    async checkSound() {
        const self = this;
        const role = self.utilService.localStorageGetItem('role');
        if (role === 'ROLE_READER' && !this.electron) {
            const testSound = self.utilService.localStorageGetItem('testSound');
            if (testSound !== 'true') {
                const component: any = await import('./modules/shared/components/modals/audio-modal/audio-modal.component').then(cp => cp.AudioModalComponent);
                this.bsModalService.show(component, {
                    animated: false,
                    class: 'modal-sm',
                    backdrop: 'static',
                    keyboard: false,
                    ignoreBackdropClick: true
                });
            }
            // Call web service to update their status to the same they have, so the server can send an email in case user restore session
            this.subs.sink = this.consultantService.sendEmail().subscribe(() => {
            });
        }


    }

    checkIfIsMobile() {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        return isMobile;
    }

    async ping() {
        const self = this;
        const userAgent = this.window.navigator.userAgent;
        if (!userAgent.includes('Lighthouse')) {
            self.utilService.setTimeout(async function () {
                try {
                    await self.userService.ping().toPromise();
                    self.subs.sink = self.intervalPing = self.utilService.setInterval(async function () {
                        try {
                            const pingResult = await self.userService.ping().toPromise();
                            /**
                             * Ping web service is returning empty {} when there is an error
                             * this is to avoid filling sentry with errors when the system is down
                             * In case ping web service is working, it returns null
                             */
                            if (pingResult) {
                                self.utilService.clearInterval(self.intervalPing);
                                // clearInterval(self.intervalPing);
                                // Show connecting message and decrease time
                                self.reconnectDialog.nativeElement.style.display = 'block';
                                self.reconnecting();
                            }
                        } catch (e) {
                            self.utilService.clearInterval(self.intervalPing);
                            // clearInterval(self.intervalPing);
                            // Show connecting message and decrease time
                            self.reconnectDialog.nativeElement.style.display = 'block';
                            self.reconnecting();
                        }
                    }, environment.intervalPing);
                } catch (e) {
                    // Show connecting message and decrease time
                    self.reconnectDialog.nativeElement.style.display = 'block';
                    self.utilService.clearInterval(self.intervalPing);
                    // clearInterval(self.intervalPing);
                    self.reconnecting();
                }
            }, 1000);
        }


    }


    reconnecting() {
        // if (!isScullyRunning()) {
        if (true) {
            const self = this;
            self.utilService.localStorageSetItem('reconnecting', 'true');
            this.subs.sink = this.intervalReconnecting = self.utilService.setInterval(async function () {
                try {
                    const pingResult = await self.userService.ping().toPromise();
                    /**
                     * Ping web service is returning empty {} when there is an error
                     * this is to avoid filling sentry with errors when the system is down
                     * In case ping web service is working, it returns null
                     */
                    if (!pingResult) {
                        self.utilService.localStorageSetItem('reconnecting', 'false');
                        self.utilService.clearInterval(self.intervalReconnecting);
                        // clearInterval(self.intervalReconnecting);
                        // Hide connecting message

                        self.reconnectDialog.nativeElement.style.display = 'none';
                        // console.og('ping no working');
                        self.window.location.reload();
                    }

                } catch (e) {
                }
            }, environment.intervalReconnection);
        }
    }

    private loadScripts() {
        const self = this;
        // if (!isScullyRunning()) {
        if (true) {

            (window as any).fbAsyncInit = function () {
                FB.init({
                    appId: '114329868900798',
                    cookie: true,
                    xfbml: true,
                    version: 'v20.0',
                });
                FB.AppEvents.logPageView();
            };

            (function (d, s, id) {
                // tslint:disable-next-line:prefer-const
                let js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(self.document, 'script', 'facebook-jssdk'));

            (function (d, s, id) {
                // tslint:disable-next-line:prefer-const
                let js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://accounts.google.com/gsi/client';
                js.async = true;
                js.defer = true;
                fjs.parentNode.insertBefore(js, fjs);
            }(self.document, 'script', 'google-signin-sdk'));

            (function (d, s, id, src) {
                // tslint:disable-next-line:prefer-const
                let js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = src;
                fjs.parentNode.insertBefore(js, fjs);
            }(self.document, 'script', 'apple-signin', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'));


        }

    }

    private loadStyles() {
        const self = this;
        self.dynamicScriptLoader.loadCss('fontsGoogleApi', 'fontsIcomoon').catch(error => {
        });
    }

    openWidget() {
        const self = this;
        // @ts-ignore
        zE('webWidget', 'show');
        // @ts-ignore
        zE('webWidget', 'open');
        // @ts-ignore
        self.document.querySelector('#myLauncher').style.opacity = 0;
        this.uid = self.utilService.localStorageGetItem('uid');
        if (this.uid) {
            const user: UserDetails = this.userService.getAuth();
            // @ts-ignore
            zE('webWidget', 'prefill', {
                name: {
                    value: user.nickName,
                    readOnly: true // optional
                }
            });
        }
    }

    closeZDeskWidget() {
        try {
            const self = this;
            // @ts-ignore
            zE('webWidget', 'hide');
            // @ts-ignore
            self.document.querySelector('#myLauncher').style.opacity = 1;
        } catch (e) {
        }
    }


    call() {
        // @ts-ignore
    }


    signOut() {
        Sentry.setUser(null);
        const self = this;
        const cookies_banner = self.utilService.localStorageGetItem('cookies_banner');
        this.subs.sink = this.userService.signOut().pipe(finalize(() => {
            try {
                // self.atmosphereService.disconnect();
                self.deleteData();
                self.electronService.removeDockMenu();
                this.window.location.href = '';
            } catch (e) {
                console.error('Error deleting cookies', e);
                this.window.location.href = '';
            }

        })).subscribe((res) => {
        }, error => {
            try {
                // self.atmosphereService.disconnect();
            } catch (e) {
            }
            try {
                self.deleteData();
            } catch (e) {
            }
            console.error(error);
            this.window.location.href = 'index.html';
        });
    }

    deleteData() {
        const self = this;
        const cookies_banner = self.utilService.localStorageGetItem('cookies_banner');
        this.cookieService.deleteAll('/');
        self.utilService.localStorageClear();
        self.utilService.localStorageSetItem('cookies_banner', cookies_banner);
    }


    /**
     * This function will determinate which css class add to the main div
     * Not Logged will add some padding to the top (because of the header)
     * Logged will add more padding to the top because the header is bigger
     * Chat will remove the padding from the top
     */
    async classMainDiv() {
        const self = this;
        const isLogged = this.userService.isLoggedIn();
        if (!isLogged) {
            this.page?.nativeElement.classList.add('not-logged');
            this.page?.nativeElement.classList.remove('logged');
        } else {
            this.page?.nativeElement.classList.add('logged');
            this.page?.nativeElement.classList.remove('not-logged');
        }

        if (this.chatRunning) {
            this.page?.nativeElement.classList.add('chat-page');
            this.myLauncher?.nativeElement.classList.add('hideLauncher');
        } else {
            this.page?.nativeElement.classList.remove('chat-page');
            this.myLauncher?.nativeElement.classList.remove('hideLauncher');
        }
    }


    private setCredentialsForLogger() {
        const token = this.utilService.localStorageGetItem('token');
        const config = this.logger.getConfigSnapshot();
        if (token) {
            config.serverLogLevel = NgxLoggerLevel.ERROR;
            config.customHttpHeaders = new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'ignoreLoadingBar': '',
                    'Authorization': 'Bearer ' + token
                }
            );
            this.logger.updateConfig(config);
        } else {
            config.serverLogLevel = NgxLoggerLevel.OFF;
            this.logger.updateConfig(config);
        }
    }

    private checkIfRegistration() {
        const self = this;
        this.route.queryParamMap.subscribe(async (queryParams: any) => {
            const registration = queryParams.get('registration');
            const registrationStep = self.utilService.localStorageGetItem('registration');
            const firstName = self.utilService.localStorageGetItem('firstName');
            const lastName = self.utilService.localStorageGetItem('lastName');
            if (registration && registrationStep) {
                if (registrationStep === 'step2') {
                    self.bsModalService.show(RegistrationStep2Component, {
                        animated: false,
                        class: 'modal-dialog-steps',
                        backdrop: true,
                        initialState: {
                            firstName,
                            lastName,
                        }
                    });
                } else if (registrationStep === 'step3') {
                    let lastPaymentSystem: any = self.utilService.localStorageGetItem('lastPaymentSystem');
                    lastPaymentSystem = lastPaymentSystem == null ? 0 : lastPaymentSystem;
                    self.bsModalService.show(RegistrationStep3Component, {
                        class: 'modal-dialog-steps',
                        animated: false,
                        backdrop: true,
                        initialState: {activeTab: lastPaymentSystem},
                    });
                }

                self.location.replaceState(self.router.url.split('?')[0]);
            }
        });
    }

    private async showOffersAfterRegistration() {
        const self = this;
        const showOffers = this.utilService.localStorageGetItem('showOffers');
        if (showOffers === 'true') {
            this.subs.sink = this.logService.addEventCC(`Showing modal for welcome offers`, NgxLoggerLevel.LOG).subscribe();
            setTimeout(async () => {
                await self.gAE.fireCCRegistrationEvent(0, self.utilService.localStorageGetItem('uid'));
            }, 3000);
            if (await this.userService.firstTimeAddingFunds() === false) {
                this.allEmiterService.onLoadingChange(false);
                return;
            }

            await this.userService.showAddFunds('Show Offers After Registration');


        }
    }

    async checkIfCCExpired() {

        const checkCC = this.utilService.localStorageGetItem('checkCC');
        const role = this.utilService.localStorageGetItem('role');
        if (role === 'ROLE_CUSTOMER' && checkCC === 'true') {

            const result = await this.userCreditCardService.checkCCExpire();
            if (result === true) {
                const component: any = await import('./modules/shared/components/modals/cc-expired-modal/cc-expired-modal.component').then(cp => cp.CcExpiredModalComponent);
                this.bsModalService.show(component, {
                    animated: false,
                    class: 'modal-sm',
                    backdrop: true,
                    keyboard: true,
                    ignoreBackdropClick: false
                });
            }
            this.utilService.localStorageRemoveItem('checkCC');
        }


    }

    async checkIfUseCyberSource() {

        /**
         * Avoid showing the modal while doing the registration
         */
        // const validateCS = this.utilService.localStorageGetItem('validateCS');
        // if (validateCS !== 'true') {
        //     return;
        // }
        const checkCyberSource = this.utilService.localStorageGetItem('checkCyberSource');

        const role = this.utilService.localStorageGetItem('role');
        if (role === 'ROLE_CUSTOMER') {
            let previousDate = new Date('2025-01-01');
            try {
                previousDate = new Date(checkCyberSource);
            } catch (e) {
                console.warn('Date no valid');
            }
            const isNextDay = this.checkIfIsNextDay(previousDate);
            const result = await this.userCreditCardService.checkCyberSource();
            let shouldShowModal = false;
            if (result.isUsingCyberSource === true) {
                return;
            }
            if (result.forceCyberSource === true) {
                shouldShowModal = true;
            } else {
                if (result.forceCyberSource === false && result.isUsingCyberSource === false && isNextDay === true) {
                    shouldShowModal = true;
                }
            }
            if (shouldShowModal === true) {
                const component: any = await import('./modules/shared/components/modals/cybersource-modal/cybersource-modal.component').then(cp => cp.CybersourceModalComponent);
                this.bsModalService.show(component, {
                    animated: false,
                    class: 'modal-sm',
                    backdrop: true,
                    keyboard: false,
                    ignoreBackdropClick: true,
                    initialState: result
                });
            }
        }


    }

    checkIfIsNextDay(previousDate: Date) {
        // Get the current date
        const currentDate = new Date();

// Extract only the year, month, and day
        const targetYear = previousDate.getFullYear();
        const targetMonth = previousDate.getMonth();
        const targetDay = previousDate.getDate();

        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

// Check if current date is at least the next day
        if (
            currentYear > targetYear ||
            (currentYear === targetYear && currentMonth > targetMonth) ||
            (currentYear === targetYear && currentMonth === targetMonth && currentDay > targetDay)
        ) {
            return true;
        } else {
            return false;
        }
    }


    pwaInstallation() {
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
        });
    }

    /**
     * It will help us to track better the user
     */
    trackUser() {
        // Get url parameter
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let referrer = '';
        const parameters = {};
        if (urlParams.get('gclid')) {
            referrer = 'Google ads';
            parameters['gclid'] = urlParams.get('gclid');
            parameters['gad'] = urlParams.get('gad');
        }
        if (urlParams.get('fbclid')) {
            referrer = 'Facebook Ads';
            parameters['fbclid'] = urlParams.get('fbclid');
        }
    }

    // public throwTestError(): void {
    //     Sentry.captureMessage('CLick on throw error');
    //     // console.og('CLick on throw error');
    //     try {
    //         throw new Error('A random error');
    //     } catch (e) {
    //         console.error(e);
    //         const resuilt = Sentry.captureException(e);
    //         // console.og(resuilt);
    //     }
    //
    // }

}
